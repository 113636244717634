import React, { useState } from 'react';
import './HindusthanHospital.css';
import ImagePaths from '../../../Utility/ImagePaths';
import {
    TextField, Select, MenuItem, InputAdornment,
    Radio, RadioGroup, FormControl, FormControlLabel,
    Box, Autocomplete
} from '@mui/material';
import { post } from '../../../Utility/RestAPIService';
import { ToastMsg } from '../../CommonFunctions/CommonFuntions';
import { City, Country, State } from 'country-state-city';

const genderList = [
    { value: 'm', label: 'Male / ஆண்' },
    { value: 'f', label: 'Female / பெண்' },
    { value: 't', label: 'Other / மற்றவர்' },
];

const refSourceList = [
    { value: 'Newspaper', label: 'Newspaper' },
    { value: 'Television', label: 'Television' },
    { value: 'Radio', label: 'Radio' },
    { value: 'Magazine', label: 'Magazine' },
    { value: 'Friends', label: 'Friends' },
    { value: 'Relative', label: 'Relative' },
    { value: 'Web-search', label: 'Web-search' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Handbills', label: 'Handbills' },
    { value: 'Bus', label: 'Bus' },
    { value: 'Hindusthan Employee', label: 'Hindusthan Employee' },
    { value: "Hindusthan Employee's Relative", label: "Hindusthan Employee's Relative" },
    { value: 'Hindusthan Doctor', label: 'Hindusthan Doctor' },
    { value: 'Hindusthan Students', label: 'Hindusthan Students' },
    { value: 'Other', label: 'Other' },
]

const HindusthanHospital = () => {
    const [formData, setFormData] = useState({
        birthdate: '',
        age: '',
        name: '',
        maritalStatus: '',
        fatherName: '',
        gender: '',
        phone: '',
        phone1: '',
        address: '',
        pincode: '',
        email: '',
        doorNo: "",
        street: "",
        city: "",
        district: "",
        state: '',
        country: '',
        cityName: City.getCitiesOfState("IN", "TN"),
        country_code: 'IN',
        state_code: 'TN',
        AllCountry: Country.getAllCountries(),
        AllStates: State.getStatesOfCountry("IN"),
        refSource: '',
        otherReferal: '',
        showOthersTextarea: false,
        showTerms: false,
        agreeTerms: false,
        isChecked: false,
        isDisable: false,
        errors: {},
        isErrorMsg: false,
        successMsg: false,
        isErrorMsgText: '',
        successMsgText: '',
    });

    const getPincodeToCity = (pincode) => {
        fetch(`https://api.postalpincode.in/pincode/${pincode}`)
            .then((res) => res.json())
            .then(response => {
                console.log(response, "response from console")
                if (response && response[0].Status === "Success") {
                    const data = response[0].PostOffice;
                    if (data && data.length > 0) {
                        const apiState = (data[0]?.State === 'Pondicherry' ? 'Puducherry' : data[0]?.State);

                        setFormData(prevFormData => {
                            const country = prevFormData.AllCountry.find(item => item.name === data[0].Country);
                            const countryIsoCode = country?.isoCode;
                            const allStates = prevFormData.state_code ? prevFormData.AllStates : State.getStatesOfCountry(countryIsoCode);
                            const stateIsoCode = allStates.find(item => item.name === apiState)?.isoCode;
                            const cityNames = prevFormData.city ? prevFormData.cityName : City.getCitiesOfState(countryIsoCode, stateIsoCode);

                            return {
                                ...prevFormData,
                                city: prevFormData.city || data[0].District,
                                AllStates: allStates,
                                country_code: prevFormData.country_code || countryIsoCode,
                                state_code: prevFormData.state_code || stateIsoCode,
                                cityName: cityNames
                            };
                        });
                    }
                } else if (response.data.status === "fail") {
                    console.error("Failed to retrieve data");
                }
            })
            .catch(e => {
                console.error("Error:", e);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const alpha = /^[a-zA-Z\s]*$/;
        const phoneNo = /^\d{0,10}$/;
        const validAadhar = /^\d{0,12}$/;
        const validPincode = /^\d{0,6}$/;

        switch (name) {
            case 'name':
            case 'fatherName':
            case 'guardianName':
                if ((alpha.test(value) && value.length <= 32) || value === "") {
                    setFormData((prevData) => ({
                        ...prevData,
                        [name]: value,
                        errors: {
                            ...prevData.errors,
                            [name]: ''
                        }
                    }));
                }
                break;
            case 'pincode':
                if ((validPincode.test(value) && value) || value === "") {
                    setFormData((prevData) => {
                        if (value && value.length === 6) {
                            getPincodeToCity(value);
                        }
                        return {
                            ...prevData,
                            pincode: value,
                            errors: {
                                ...prevData.errors,
                                pincode: ''
                            }
                        };
                    });
                }
                break;
            case 'birthdate':
                setFormData((prevData) => ({
                    ...prevData,
                    birthdate: value,
                    errors: {
                        ...prevData.errors,
                        age: '',
                        birthdate: ''
                    }
                }));
                calculateAgeFromBirthdate(value);
                break;
            case 'age':
                if ((value >= 0 && value <= 150) || value === "") {
                    setFormData((prevData) => ({
                        ...prevData,
                        age: value,
                        errors: {
                            ...prevData.errors,
                            age: '',
                            birthdate: ''
                        }
                    }));
                    if (value !== "") {
                        calculateBirthdateFromAge(value);
                    }
                }
                break;
            case 'email':
                if ((value?.length < 50 && value) || value === "") {
                    setFormData((prevData) => ({
                        ...prevData,
                        email: value,
                        errors: {
                            ...prevData.errors,
                            email: ''
                        }
                    }));
                }
                break;
            case 'phone':
            case 'phone1':
                if ((phoneNo.test(value) && value) || value === "") {
                    setFormData((prevData) => ({
                        ...prevData,
                        [name]: value,
                        errors: {
                            ...prevData.errors,
                            [name]: ''
                        }
                    }));
                }
                break;
            case 'aadharNo':
                if ((validAadhar.test(value) && value) || value === "") {
                    setFormData((prevData) => ({
                        ...prevData,
                        aadharNo: value,
                        errors: {
                            ...prevData.errors,
                            aadharNo: ''
                        }
                    }));
                }
                break;
            case 'address':
                if ((value?.length <= 100 && value) || value === "") {
                    setFormData((prevData) => ({
                        ...prevData,
                        address: value,
                        errors: {
                            ...prevData.errors,
                            address: ''
                        }
                    }));
                }
                break;
            case 'occupation':
                if ((value?.length <= 50 && value) || value === "") {
                    setFormData((prevData) => ({
                        ...prevData,
                        occupation: value,
                        errors: {
                            ...prevData.errors,
                            occupation: ''
                        }
                    }));
                }
                break;
            case 'state_code':
                if (value || value === "") {
                    setFormData((prevData) => ({
                        ...prevData,
                        state_code: value,
                        cityName: City.getCitiesOfState(prevData.country_code, value),
                        city: '',
                        errors: {
                            ...prevData.errors,
                            state_code: ''
                        }
                    }));
                }
                break;
            case 'country_code':
                if (value || value === "") {
                    setFormData((prevData) => ({
                        ...prevData,
                        country_code: value,
                        state_code: "",
                        city: '',
                        AllStates: State.getStatesOfCountry(value),
                        cityName: '',
                        errors: {
                            ...prevData.errors,
                            state_code: ''
                        }
                    }));
                }
                break;
            default:
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                    errors: {
                        ...prevData.errors,
                        [name]: ''
                    }
                }));
                break;
        }

    };

    const renderTextField = (statekey, maxLength, type = "text", placeholder = "") => {
        return (
            <Box>
                <TextField
                    value={formData[statekey]}
                    name={statekey}
                    inputProps={maxLength ? { maxLength: maxLength } : {}}
                    autoComplete='off'
                    onChange={handleInputChange}
                    className='textField'
                    size='small'
                    type={type}
                    InputProps={{
                        startAdornment: placeholder && (
                            <InputAdornment position="start">
                                <div >{placeholder}</div>
                            </InputAdornment>
                        ),
                    }}
                />
                {formData?.errors[statekey] ? <p className="hindus_error_txt" data-testid="eMed_dobError">{formData?.errors[statekey]}</p> : null}
            </Box>
        )
    }

    const renderSelect = (statekey, list = genderList, showName = "label", valueName = "value") => {
        return (
            <Box>
                <Select
                    name={statekey}
                    value={formData[statekey]}
                    size='small'
                    className='textField'
                    onChange={handleInputChange}
                    sx={{marginTop: "0px"}}
                    MenuProps={{
                        disablePortal: true,
                        PaperProps: {
                            style: {
                                maxHeight: 200,
                            },
                        },
                    }}
                >
                    {list?.map((item) => (
                        <MenuItem key={item[valueName]} value={item[valueName]}>
                            {item[showName]}
                        </MenuItem>
                    ))}
                </Select>
                {formData?.errors[statekey] ? <p className="hindus_error_txt" data-testid="eMed_dobError">{formData?.errors[statekey]}</p> : null}
            </Box >
        )
    }

    const handleAutoComplete = (statekey, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [statekey]: value,
            errors: {
                ...prevData.errors,
                [statekey]: ''
            }
        }));
    }

    const renderAutoComplete = (statekey, list, showName, valueName) => {
        return (
            <Box>
            <Autocomplete
                className='textField'
                size="small"
                autoComplete="off"
                sx={{marginTop: "0px"}}
                options={list || []}
                getOptionLabel={(option) => typeof option === "string" ? option : option[showName]}
                onChange={(e, value) => handleAutoComplete(statekey, value ? value[valueName] : "")}
                clearIcon={false}
                value={formData[statekey]}
                disablePortal
                isOptionEqualToValue={(option, value) =>
                    value ? option[valueName] === value[valueName] : false
                }
                renderInput={(params) => (
                    <TextField
                        name={`city_${statekey}`}
                        InputProps={{ ...params.InputProps }}
                        onChange={(e) => handleAutoComplete(statekey, e.target.value)}
                        {...params}
                        autoComplete="off"
                    />
                )}
                onKeyDown={(e) => e.key === "Enter" ? e.preventDefault() : null}
            />
            {formData?.errors[statekey] ? <p className="hindus_error_txt" data-testid="eMed_dobError">{formData?.errors[statekey]}</p> : null}
            </Box>
        );
    };
            
    const today = new Date().toISOString().split('T')[0];

    const calculateAgeFromBirthdate = (birthdate) => {
        const birthdateDate = new Date(birthdate);
        const today = new Date();
        let calculatedAge = today.getFullYear() - birthdateDate.getFullYear();
        const monthDifference = today.getMonth() - birthdateDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthdateDate.getDate())) {
            calculatedAge--;
        }
        if (calculatedAge <= 150 && calculatedAge >= 0) {
            setFormData((prevData) => ({
                ...prevData,
                age: calculatedAge,
            }));
        }
    };

    const calculateBirthdateFromAge = (inputAge) => {
        const today = new Date();
        today.setFullYear(today.getFullYear() - inputAge);
        setFormData((prevData) => ({
            ...prevData,
            birthdate: today.toISOString().split('T')[0],
        }));
    };

    const errorMessage = (message) => {
        setFormData((prevData) => ({
            ...prevData,
            isErrorMsg: true,
            isErrorMsgText: message,
        }));
    }

    const successMessage = (message) => {
        setFormData((prevData) => ({
            ...prevData,
            successMsg: true,
            successMsgText: message
        }));
    }

    const msgClose = () => {
        setFormData((prevData) => ({
            ...prevData,
            isErrorMsg: false,
            successMsg: false,
            isErrorMsgText: '',
            successMsgText: ''
        }));
    }

    const handleDisable = (flag) => {
        setFormData((prevData) => ({
            ...prevData,
            isDisable: flag,
        }));
    }

    const toggleTermsVisibility = () => {
        setFormData((prevData) => ({
            ...prevData,
            showTerms: !prevData.showTerms,
        }));
    };

    const handleAgreeTermsChange = (event) => {
        setFormData((prevState) => ({
            ...prevState,
            agreeTerms: event.target.checked,
            isChecked: event.target.checked,
            errors: { ...prevState.errors, agreeTerms: '' }
        }));
    };

    const postPatientDetail = () => {
        let REGISTRATION_FORM_POST = 'fo/qr-appointment/registration/'
        try {
            const adress_line_1 = `${formData?.doorNo || ""} ${formData?.street || ""}`;
            let data = {
                "clinic_id": 10,
                "patient_name": formData?.name ? formData?.name : "",
                "gender": formData?.gender ? formData?.gender : "",
                "dob": formData?.birthdate ? formData?.birthdate : null,
                "age": formData?.age ? formData?.age : null,
                "address_line_1": adress_line_1 ? adress_line_1 : "",
                "pincode": formData?.pincode ? formData?.pincode : "",
                "mobile_number": formData?.phone ? formData?.phone : "",
                "email": formData?.email ? formData?.email : null,
                'father_name': formData?.fatherName ? formData?.fatherName : "",
                'marital_status': formData?.maritalStatus ? formData?.maritalStatus : "",
                'guardian_name': "",
                'aadhar_number': null,
                'occupation': "",
                "referral_source": formData?.refSource ? formData?.refSource : "",
                "city": formData?.city ? formData?.city : "",
                'state': formData?.state_code ? formData?.AllStates.find((item)=>( item.isoCode === formData.state_code))?.name : "",
                'address_line_2': formData?.district ? formData?.district : "",
                "country": formData?.country_code ? formData?.AllCountry.find((item)=>( item.isoCode === formData.country_code))?.name : "",
                "emergency_number": formData?.phone1 ? formData?.phone1 : null,
            }
            post(REGISTRATION_FORM_POST, data)
                .then((response) => {
                    if (response.data.status === 'success') {
                        successMessage(response.data.message)
                        setFormData((prevData) => ({
                            ...prevData,
                            birthdate: '',
                            age: '',
                            name: '',
                            fatherName: '',
                            maritalStatus: '',
                            gender: '',
                            phone: '',
                            phone1: '',
                            address: '',
                            pincode: '',
                            email: '',
                            doorNo: "",
                            street: "",
                            city: "",
                            district: "",
                            state: '',
                            country: '',
                            cityName: City.getCitiesOfState("IN", "TN"),
                            country_code: 'IN',
                            state_code: 'TN',
                            AllCountry: Country.getAllCountries(),
                            AllStates: State.getStatesOfCountry("IN"),
                            refSource: '',
                            otherReferal: '',
                            showOthersTextarea: false,
                            showTerms: false,
                            agreeTerms: false,
                            isChecked: false,
                            isDisable: false,
                            errors: {},
                            isErrorMsg: false,
                            successMsg: false,
                            isErrorMsgText: '',
                            successMsgText: '',
                        }));
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                        handleDisable(false)
                    } else {
                        errorMessage(error.message)
                        handleDisable(false)
                    }
                })
        } catch (error) {
            errorMessage(error.message)
            handleDisable(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = {};
        handleDisable(true);
        const validateRequiredField = (field, message) => {
            if (!formData[field]) newErrors[field] = message;
        };

        // Validate required fields
        validateRequiredField("name", 'Name is required');
        validateRequiredField("gender", 'Please select Gender');
        validateRequiredField("birthdate", 'Birthdate is required');
        validateRequiredField("age", 'Age is required');
        validateRequiredField("phone", 'Mobile No 01 is required');
        validateRequiredField("maritalStatus", 'Marital Status is required');
        validateRequiredField("fatherName", 'Father/Spouse/ Guardian Name is required');
        validateRequiredField("doorNo", 'Door No is required');
        validateRequiredField("street", 'Street is required');
        validateRequiredField("city", 'City is required');
        validateRequiredField("district", 'District is required');
        validateRequiredField("state_code", 'State is required');
        validateRequiredField("country_code", 'Country is required');
        validateRequiredField("pincode", 'Pincode is required');

        if (formData.phone && !/^\d{10}$/.test(formData.phone?.trim())) {
            newErrors.phone = 'Please enter a valid Phone Number.';
        }
        if (formData.phone1 && !/^\d{10}$/.test(formData.phone1?.trim())) {
            newErrors.phone1 = 'Please enter a valid Phone Number.';
        }
        if (formData.email && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
            newErrors.email = 'Please enter a valid E-mail';
        }

        if (formData.pincode && !/^\d{6}$/.test(formData.pincode)) {
            newErrors.pincode = 'Please enter a valid Pincode';
        }

        setFormData((prevData) => ({
            ...prevData,
            errors: newErrors,
        }));

        if (Object.keys(newErrors).length === 0) {
            postPatientDetail();
        } else {
            handleDisable(false);
        }
    };

    console.log(formData);
    

    return (
        <div className="formpage container overAllcontainer" data-testid="eMed_formpage">
            <div className="header" data-testid="eMed_header">
                <img src={ImagePaths.HindusthanHeaderImage} alt="Header" data-testid="eMed_headerImage" />
            </div>
            <h3 className="hindus_title">Out Patient Registration Information Form</h3>
            <h3 className="hindus_title">வெளி நோயாளி பதிவு தகவல் படிவம்</h3>
            <form onSubmit={handleSubmit} className="formPageDetails" data-testid="eMed_form">
                <div className='hindus_rowOne'>
                    <div className='rowOneSub'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">Patient Name / நோயாளியின் பெயர் *</label>
                        {renderTextField("name", 32)}
                    </div>
                    <div className='rowOneSub'style ={{width: "48.5%"}}>
                        <div className='hindus_rowOne'>
                            <div className='rowOneSub'>
                                <label className="hindus_labels" data-testid="eMed_genderLabel">Gender / பாலினம் *</label>
                                {renderSelect("gender")}
                            </div>
                            <div className='rowOneSub'>
                                <label className="hindus_labels" data-testid="eMed_nameLabel">Age / வயது *</label>
                                {renderTextField("age")}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='hindus_rowOne'>
                    <div className='rowOneSub'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">Date of Birth / பிறந்த தேதி *</label>
                        <input type="date" max={today} value={formData.birthdate} onChange={handleInputChange} data-testid="eMed_dobInput" className='datePicker' />
                        {formData?.errors?.birthdate ? <p className="hindus_error_txt" data-testid="eMed_dobError">{formData?.errors?.birthdate}</p> : null}
                    </div>
                    <div className='rowOneSub'>
                        <label className="hindus_labels" data-testid="eMed_genderLabel">Marital Status / திருமண நிலை*</label>
                        <FormControl sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <RadioGroup
                                name="maritalStatus"
                                value={formData.maritalStatus}
                                onChange={handleInputChange}
                                sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                            >
                                <FormControlLabel
                                    value={"Single"}
                                    control={<Radio />}
                                    label={"UnMarried / திருமணமாகாதவர்"}
                                    sx={{
                                        color: "#B61B1F",
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '13.23px',
                                            fontWeight: '500',
                                            textTransform: 'capitalize',
                                        },
                                        '& .MuiRadio-root': {
                                            '&.Mui-checked': {
                                                color: '#B61B1F',
                                            },
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    value={"Married"}
                                    control={<Radio />}
                                    label={"Married / திருமணமானவர்"}
                                    sx={{
                                        color: "#B61B1F",
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '13.23px',
                                            fontWeight: '500',
                                            textTransform: 'capitalize',
                                        },
                                        '& .MuiRadio-root': {
                                            '&.Mui-checked': {
                                                color: '#B61B1F',
                                            },
                                        },
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>
                        {formData?.errors?.maritalStatus ?
                            <p className="hindus_error_txt" style={{ marginTop: "2px" }} data-testid="eMed_genderError">
                                {formData?.errors?.maritalStatus}</p> : null}
                    </div>
                </div>

                <div className='hindus_rowOne' >
                    <div className='rowOneSub'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel" style={{ whiteSpace: "wrap" }}>
                            Father/Spouse/ Guardian Name / தந்தை/மனைவி/
                            பாதுகாப்பாளர் பெயர்*
                        </label>
                        {renderTextField("fatherName", 32)}
                    </div>
                    <div className='rowOneSub'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">Mobile No 01 / கைபேசி எண் *</label>
                        {renderTextField("phone", 10, "text", "+91")}
                    </div>
                </div>

                <div className='hindus_rowOne'>
                    <div className='rowOneSub'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">Mobile No 02 / கைபேசி எண் 02</label>
                        {renderTextField("phone1", 10, "text", "+91")}
                    </div>
                    <div className='rowOneSub email'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">Email Id / மின்னஞ்சல் முகவரி</label>
                        {renderTextField("email", 50, "email")}
                    </div>
                </div>
                <hr style={{ margin: "5px 0px" }} />
                <label className="hindus_labels" data-testid="eMed_nameLabel">Current Address</label>
                <div className='hindus_rowOne'>
                    <div className='rowOneSub'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">Door No/கதவு எண்*</label>
                        {renderTextField("doorNo", 10)}
                    </div>
                    <div className='rowOneSub email'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">Street/தெரு பெயர்*</label>
                        {renderTextField("street", 50)}
                    </div>
                </div>
                <div className='hindus_rowOne'>
                    <div className='rowOneSub'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">City/நகரம்*</label>
                        {renderAutoComplete("city", formData.cityName, "name", "name")}
                    </div>
                    <div className='rowOneSub email'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">District/மாவட்டம்*</label>
                        {renderTextField("district", 50)}
                    </div>
                </div>

                <div className='hindus_rowOne'>
                    <div className='rowOneSub'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">State/மாநிலம்*</label>
                        {renderSelect("state_code", formData.AllStates, "name", "isoCode")}
                    </div>
                    <div className='rowOneSub email'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">Country/நாடு*</label>
                        {renderSelect("country_code", formData.AllCountry, "name", "isoCode")}
                    </div>
                </div>

                <div className='hindus_rowOne'>
                    <div className='rowOneSub'>
                        <label className="hindus_labels" data-testid="eMed_nameLabel">Pin-code /பின்கோடு*</label>
                        {renderTextField("pincode", 6)}
                    </div>
                </div>

                <hr style={{ margin: "5px 0px" }} />
                <h3 className="hindus_labels" data-testid="eMed_nameLabel">How do you know about Hindustan hospital?</h3>
                <FormControl sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <RadioGroup
                        name="refSource"
                        value={formData.refSource}
                        onChange={handleInputChange}
                        sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                    >
                        {refSourceList?.map((source) => (
                            <FormControlLabel
                                key={source.value}
                                value={source.value}
                                control={<Radio />}
                                label={source.label}
                                sx={{
                                    color: "black",
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '13.23px',
                                        fontWeight: '500',
                                        textTransform: 'capitalize',
                                    },
                                    '& .MuiRadio-root': {
                                        '&.Mui-checked': {
                                            color: '#B61B1F',
                                        },
                                    },
                                }}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
                <hr style={{ margin: "5px 0px" }} />
                <div className="termsandcondition" data-testid="eMed_termsandcondition">
                    <span className="tandcall">
                        <input type="checkbox" id="termsandcondition" checked={formData.isChecked} name="termsandcondition" value="howdidyou" onChange={handleAgreeTermsChange} data-testid="eMed_termsCheckbox" />
                        <p className="hindus_labels" style={{ color: "black" }} data-testid="eMed_termsToggle">
                            I agree to the <span className="hindus_labels" style={{ textDecoration: "underline", cursor: "pointer" }} onClick={toggleTermsVisibility}>Terms & Conditions</span> for booking this appointment.
                        </p>
                    </span>
                    {formData?.showTerms && (
                        <p className="termspara label" data-testid="eMed_termsText">
                            <span>I have been appraised of the rules & regulations of the Hospital and also the charges for treatment. I give my consent, to be examined / the patient to be examined by the Doctors. Nurses of the Hospital and to undergo essential test and procedures for diagnosis and take treatment by the Doctor.                         </span>  <br /><br /><br />
                            <span>இம்மருத்துவமனையில் நோயாளிகள் சிகிச்சை பெறுவதற்கான சட்ட திட்டங்களையும் கட்டண விகிதங்களையும் தெளிவுற அறிந்தேன். மேலும் இம்மருத்துவமனையின் மருத்துவர்கள். மருத்துவ ஆலோசகர்கள் மற்றும் செவிலியர்கள், எனது / நோயாளியின் சிகிச்சையின் நிமித்தம் என்னை / நோயாளியை உடற்பரிசோதனை மேற்கொள்ளவும் நோயை கண்டறிய அத்தியாவசிய பரிசோதனைகள் மற்றும் சிகிச்சைகள் எனக்கு / நோயாளிக்கு அளிக்கவும் மனப்பூர்வமான எனது சம்மதத்தை இதன்மூலம் தெரிவித்துக் கொள்கிறேன்.</span>
                        </p>
                    )}
                    {formData?.errors?.agreeTerms && <p className="erroragree" data-testid="eMed_termsError">{formData?.errors?.agreeTerms}</p>}
                </div>

                <div className="hindus_submitbutton">
                    <input disabled={formData.isDisable || !formData.isChecked} type="submit" value="Submit" data-testid="eMed_submitButton" style={{ backgroundColor : (formData.isDisable || !formData.isChecked) ? 'gray' : "#B61B1F" }}/>
                </div>
            </form>
            <div className="imagesall" data-testid="eMed_imagesall">
                <div className="emedlogo">
                    <span className="alreadypat"><p data-testid="eMed_emedLogoText">Download EmedHub </p> To view your reports and bills</span>
                </div>
                <div className="appstoreandplaystore" data-testid="eMed_appstoreAndPlaystore">
                    <a href="https://apps.apple.com/us/app/emedhub/id6475773185?platform=iphone*"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-testid="eMed_appstoreLink">
                        <img src={ImagePaths.AppStore} alt="Header" data-testid="eMed_appstoreImage" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.emedhub.clinic&pcampaignid=web_share"
                        target="_blank" rel="noopener noreferrer"
                        className="playstore" data-testid="eMed_playstoreLink">
                        <img src={ImagePaths.PlayStore} alt="Header" data-testid="eMed_playstoreImage" />
                    </a>
                </div>
            </div>
            {formData.isErrorMsg ?
                <ToastMsg
                    severity={'error'}
                    msg={formData.isErrorMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
            {formData.successMsg ?
                <ToastMsg
                    severity={'success'}
                    msg={formData.successMsgText}
                    msgPop={msgClose.bind(this)}
                />
                : null}
        </div>
    );
};

export default HindusthanHospital;