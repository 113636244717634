import axios from "axios";
import { localGetItem } from "../Components/CommonFunctions/CommonFuntions";

// const BASE_DOMAIN = "https://devv4api.emedhub.in/" //DEV API URL
const BASE_DOMAIN = "https://qav4api.emedhub.in/" //QA API URL
// const BASE_DOMAIN = "https://uat.v4.api.emedhub.in/"; //UAT API URL
// const BASE_DOMAIN = "https://api.emedhub.in/" ; //Production API URL


const get = (url) => {
  if (!window.navigator.onLine) {
    return Promise.reject(new Error('No internet connection available'));
  } else {
    return axios.create({
      baseURL: BASE_DOMAIN,
      headers: {
        "Content-type": "application/json",
        "Authorization": "Token " + localGetItem("AccessToken")
      }
    }).get("/" + url);
  }
}
const post = (url, data) => {
  if (!window.navigator.onLine) {
    return Promise.reject(new Error('No internet connection available'));
  } else {
    return axios.create({
      baseURL: BASE_DOMAIN,
      headers: {
        "Content-type": "application/json",
      }
    }).post("/" + url, data);
  }
}

const formGet = (url, token) => {
  if (!window.navigator.onLine) {
    return Promise.reject(new Error('No internet connection available'));
  } else {
    return axios.create({
      baseURL: BASE_DOMAIN,
      headers: {
        "Content-type": "application/json",
        "Authorization": "Token " + (token ? token : localGetItem("AccessToken"))
      }
    }).get("/" + url);
  }
}

export { get, post, formGet };