import React, { useEffect, useState } from "react";
import ImagePaths from "../../../Utility/ImagePaths";
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { calculateAgeFromBirthdate, calculateBirthdateFromAge, ToastMsg } from "../../CommonFunctions/CommonFuntions";
import CommonValidation from "../../CommonFunctions/CommonValidation";
import { City, Country, State } from "country-state-city";
import { formGet, post } from "../../../Utility/RestAPIService";

const TITLE = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Master', label: 'Master' },
    { value: 'Baby', label: 'Baby' },
    { value: 'Baby of', label: 'Baby of' },
]

const MARITAL_STATUS = [
    { value: 'Single', label: 'Single' },
    { value: 'Married', label: 'Married' },
    { value: 'Divorced', label: 'Divorced' },
]

const GENDER_LIST = [
    { value: 'm', label: 'Male' },
    { value: 'f', label: 'Female' },
    { value: 't', label: 'Transgender' },
]

const RELATION_SHIP = [
    { value: 1, label: 'Father' },
    { value: 2, label: 'Husband' },
]

const FONT_SIZE_XS = '0.5rem'
const FONT_SIZE_SM = '0.65rem'
const FONT_SIZE_MD = '0.8rem'

let patientDataList = {
    patient_name: '',
    patient_title: '',
    patient_gender: '',
    patient_dob: '',
    patient_age: '',
    patient_martial_sts: '',
    patient_relationship_fat_hus: '',
    patient_father_hus_name: '',
    patient_address_one: '',
    patient_address_two: '',
    patient_city: '',
    patient_state: State.getStateByCode('TN'),
    patient_pincode: '',
    patient_occupation: '',
    patient_guardian_name: '',
    patient_mob_no: '',
    patient_email_id: '',
    patient_referral_source: '',
    agreement_check: false,
    patient_country: '',
    purposeOfVisit: '',
}

export const PrathipaHospital = () => {
    const [patientData, setPatientData] = useState(patientDataList)
    const [referralSource, setReferralSource] = useState([])
    const [cityList, setCityList] = useState(City.getCitiesOfState('IN', 'TN'))
    const [stateList, setStateList] = useState(State.getStatesOfCountry('IN'))
    const [countryList, setCountryList] = useState(Country.getAllCountries())
    const [isErrorMsg, setIsErrorMsg] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [isErrorMsgText, setIsErrorMsgText] = useState('');
    const [successMsgText, setsuccessMsgText] = useState('');
    const [clinicData, setClinicData] = useState({})
    const [token, setToken] = useState('')
    const [postLoad, setPostLoad] = useState(false)
    const [getLoad, setGetLoad] = useState(true)
    const [povOptions, setPovOptions] = useState([])

    const errorMessage = (message) => {
        setIsErrorMsg(true)
        setIsErrorMsgText(message)
    }

    const successMessage = (message) => {
        setsuccessMsg(true)
        setsuccessMsgText(message)
    }

    const msgClose = () => {
        setIsErrorMsg(false)
        setIsErrorMsgText('')
        setsuccessMsg(false)
        setsuccessMsgText('')
    }

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString)
        const tokenFromUrl = urlParams.get('token')

        if (tokenFromUrl) {
            setToken(tokenFromUrl)
        }
    }, [])

    useEffect(() => {
        if (token) { getClinicData() }
    }, [token])

    const onChangeHandler = (e, key) => {
        try {
            let { value } = e.target
            switch (key) {
                case 'patient_age':
                    if (CommonValidation.numberOnly(value) && value <= 150 || value === '') {
                        setPatientData((prevPatientData) => ({
                            ...prevPatientData,
                            [key]: value,
                            patient_dob: value ? calculateAgeFromBirthdate(value) : ''
                        }))
                    }
                    break;
                case 'patient_dob':
                    setPatientData((prevPatientData) => ({
                        ...prevPatientData,
                        [key]: value,
                        patient_age: value ? calculateBirthdateFromAge(value) : ''
                    }))
                    break;
                case 'patient_name':
                    if (CommonValidation.alphabetOnly(value) && value?.length <= 32 || value === '') {
                        setPatientData((prevPatientData) => ({ ...prevPatientData, [key]: value }))
                    }
                    break;
                case 'patient_mob_no':
                    if (CommonValidation.numberOnly(value) && value?.length <= 10 || value === '') {
                        setPatientData((prevPatientData) => ({ ...prevPatientData, [key]: value }))
                    }
                    break;
                case 'agreement_check':
                    setPatientData((prevPatientData) => ({ ...prevPatientData, [key]: e.target.checked }))
                    break;
                case 'patient_relationship_fat_hus':
                    setPatientData((prevPatientData) => ({ ...prevPatientData, [key]: value }))
                    break;
                case 'patient_pincode':
                    if (CommonValidation.numberOnly(value) && value?.length <= 6 || value === '') {
                        setPatientData((prevPatientData) => ({ ...prevPatientData, [key]: value }))
                    }
                    break;
                case 'patient_father_hus_name':
                    if (CommonValidation.alphabetOnly(value) && value?.length <= 32 || value === '') {
                        setPatientData((prevPatientData) => ({ ...prevPatientData, [key]: value }))
                    }
                    break;
                case 'patient_gender':
                    setPatientData((prevPatientData) => ({
                        ...prevPatientData,
                        [key]: value,
                        patient_title: value === 'm' ? 'Mr' : value === 'f' ? 'Ms' : '',
                    }))
                    break;
                case 'patient_title':
                    setPatientData((prevPatientData) => ({
                        ...prevPatientData,
                        [key]: value,
                        patient_gender: value === 'Mr' ? 'm' : (value === 'Ms' || value === 'Mrs') ? 'f' : '',
                    }))
                    break;
                case 'patient_occupation':
                    if (CommonValidation.alphabetOnly(value) && value?.length <= 45 || value === '') {
                        setPatientData((prevPatientData) => ({ ...prevPatientData, [key]: value }))
                    }
                    break;
                default:
                    if (value?.length <= 100 || value === '') {
                        setPatientData((prevPatientData) => ({ ...prevPatientData, [key]: value }))
                    }
                    break;
            }
        } catch (error) {
            errorMessage(error.message)
        }
    }

    const checkValidation = () => {
        try {
            let message = null;
            if (!patientData.patient_name) {
                message = 'Enter Patient Name'
            } else if (!patientData.patient_gender) {
                message = 'Enter Patient Gender'
            } else if (!patientData.patient_dob) {
                message = 'Enter Patient Date of Birth'
            } else if (!patientData.patient_father_hus_name || !patientData.patient_relationship_fat_hus) {
                message = 'Enter Patient Father or Husband Name'
            } else if (!patientData?.patient_city) {
                message = 'Select Patient City'
            } else if (patientData?.patient_pincode === '') {
                message = 'Enter Patient Pincode'
            } else if (patientData.patient_mob_no === '') {
                message = 'Enter Patient Mobile Number'
            } else if (patientData.patient_mob_no !== '' && patientData?.patient_mob_no?.length < 10) {
                message = 'Invalid Mobile Number'
            } else if (!patientData?.purposeOfVisit) {
                message = 'Please Select Purpose Of Visit'
            } else if (!patientData?.agreement_check) {
                message = 'Please Click the Terms & Conditions'
            }
            return message;
        } catch (error) {
            errorMessage(error.message)
        }
    }

    const getClinicData = () => {
        try {
            let GET_CLINIC_DATA = `fo/qr-decrypt-token-details/`
            formGet(GET_CLINIC_DATA, token).then((response) => {
                if (response.data.status === 'success') {
                    setClinicData(response.data.data)
                    setReferralSource(response?.data?.data?.referral_source)
                    setPovOptions(response?.data?.data?.purpose_of_visit || [])
                    setGetLoad(false)
                }
            }).catch((error) => {
                setGetLoad(false)
                if (error?.response?.data?.message) {
                    errorMessage(error.response.data.message)
                } else {
                    errorMessage(error.message)
                }
            })
        } catch (error) {
            setGetLoad(false)
            errorMessage(error.message)
        }
    }

    const onSubmitHandler = () => {
        try {
            let REGISTRATION_FORM_POST = 'fo/qr-appointment/registration/'
            let stateName = stateList.find((item) => (item.isoCode === patientData?.patient_state?.isoCode))?.name
            let data = {
                "clinic_id": clinicData?.clinic_data?.clinic_id ? clinicData?.clinic_data?.clinic_id : null,
                "patient_name": patientData.patient_name ? patientData.patient_name : "",
                "gender": patientData.patient_gender ? patientData.patient_gender?.value : "",
                "dob": patientData.patient_dob ? patientData?.patient_dob : null,
                "age": patientData?.patient_age ? patientData?.patient_age : '',
                "address_line_1": patientData?.patient_address_one ? patientData?.patient_address_one : "",
                'address_line_2': patientData?.patient_address_two ? patientData?.patient_address_two : "",
                "pincode": patientData?.patient_pincode ? patientData?.patient_pincode : "",
                "mobile_number": patientData?.patient_mob_no ? patientData?.patient_mob_no : "",
                "email": patientData?.patient_email_id ? patientData?.patient_email_id : null,
                'father_name': patientData?.patient_father_hus_name ? patientData?.patient_father_hus_name : "",
                "referral_source": patientData?.patient_referral_source ? patientData?.patient_referral_source : '',
                'marital_status': patientData?.patient_martial_sts ? patientData?.patient_martial_sts : '',
                'guardian_name': patientData?.patient_guardian_name ? patientData?.patient_guardian_name : '',
                'occupation': patientData?.patient_occupation ? patientData?.patient_occupation : '',
                'title': patientData?.patient_title ? patientData?.patient_title?.value : '',
                'city': patientData?.patient_city ? patientData?.patient_city : '',
                'state': stateName ? stateName : '',
                'country': 'India',
                'relationship_label': patientData?.patient_relationship_fat_hus ? patientData?.patient_relationship_fat_hus?.value : '',
                'purpose_of_visit': patientData?.purposeOfVisit ? patientData?.purposeOfVisit : '',
            }
            let checkValid = checkValidation()
            if (checkValid === null) {
                post(REGISTRATION_FORM_POST, data).then((response) => {
                    if (response.data.status === 'success') {
                        successMessage(response.data.message)
                        setStateList(State.getStatesOfCountry('IN'))
                        setCityList(City.getCitiesOfState('IN', 'TN'))
                        setPatientData(patientDataList)
                        setPostLoad(false)
                    }
                }).catch((error) => {
                    setPostLoad(false)
                    if (error?.response?.data?.message) {
                        errorMessage(error.response.data.message)
                    } else {
                        errorMessage(error.message)
                    }
                })
            } else {
                setPostLoad(false)
                errorMessage(checkValid)
            }
        } catch (error) {
            errorMessage(error.message)
        }
    }

    return (
        getLoad ? <Box
            sx={{
                border: "0.5dvw solid #04B7B1",
                margin: { xs: "2dvh 5dvw", sm: "2dvh 10dvw", lg: "2dvh 20dvw" },
                borderRadius: "0.5dvw",
                boxShadow: '0 0 10px 5px #DCD8D8',
                display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100dvh'
            }}><CircularProgress color="primary" /></Box> :
            <Box
                sx={{
                    border: "0.5dvw solid #04B7B1",
                    margin: { xs: "2dvh 5dvw", sm: "2dvh 10dvw", lg: "2dvh 20dvw" },
                    borderRadius: "0.5dvw",
                    boxShadow: '0 0 10px 5px #DCD8D8'
                }}
            >
                {/* Header Image */}
                <Box display="flex" justifyContent="center" sx={{ mb: "1dvh" }}>
                    <Box
                        component="img"
                        src={Object.keys(clinicData)?.length > 0 ? clinicData?.clinic_data?.clinic_header_url : ImagePaths.RajeshHeader}
                        alt="Header"
                        sx={{
                            width: { xs: "85dvw", sm: "75dvw", md: "55dvw" },
                            height: "auto",
                        }}
                    />
                </Box>

                {/* Form Title */}
                <Box sx={{ backgroundColor: "#E4F2F0", p: "0.5dvw", textAlign: "center" }}>
                    <Typography
                        color="#006C69"
                        fontWeight={600}
                        sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}
                    // className="eMed_custom_font_size"
                    >
                        Out Patient Registration Information Form
                    </Typography>
                    <Typography
                        color="#006C69"
                        fontWeight={600}
                        sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}
                    >
                        வெளி நோயாளி பதிவு தகவல் படிவம்
                    </Typography>
                </Box>

                <Box sx={{ p: "2dvw", borderBottom: '1px solid lightgray' }}>
                    <Grid container spacing={2}>
                        {/* row One */}
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Patient Name / நோயாளியின் பெயர் *</Typography>
                            <Box display={'flex'}>
                                <Autocomplete
                                    clearIcon
                                    size="small"
                                    options={TITLE}
                                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option.label}
                                    value={patientData?.patient_title}
                                    onChange={(e, newValue) => {
                                        setPatientData((prevPatientData) => ({
                                            ...prevPatientData,
                                            patient_title: newValue,
                                            patient_gender: newValue?.value === 'Mr' ? { label: 'Male', value: 'm' } : (newValue?.value === 'Ms' || newValue?.value === 'Mrs') ? { label: 'Female', value: 'f' } : '',
                                        }))
                                    }}
                                    sx={{ width: '27%' }}
                                    renderInput={(params) => <TextField {...params} sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }} />}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option?.label}>
                                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>{option?.label}</Typography>
                                        </li>
                                    )}
                                />
                                <TextField size="small" sx={{ width: "73%", fontSize: "1dvw" }}
                                    autoComplete="off"
                                    value={patientData.patient_name}
                                    onChange={(e) => onChangeHandler(e, 'patient_name')}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Gender / பாலினம் *</Typography>
                            <Autocomplete
                                clearIcon
                                size="small"
                                options={GENDER_LIST}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option.label}
                                value={patientData?.patient_gender}
                                onChange={(e, newValue) => {
                                    setPatientData((prevPatientData) => ({
                                        ...prevPatientData,
                                        patient_gender: newValue,
                                        patient_title: newValue?.value === 'm' ? { label: 'Mr', value: 'Mr' } : newValue?.value === 'f' ? { label: 'Ms', value: 'Ms' } : '',
                                    }))
                                }}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} sx={{ width: '100%', fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }} />}
                                renderOption={(props, option) => (
                                    <li {...props} key={option?.label}>
                                        <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>{option?.label}</Typography>
                                    </li>
                                )}
                            />
                        </Grid>
                        {/* Row Two */}
                        <Grid item xs={12} sm={6} display={'flex'}>
                            <Box component={'div'} width={'70%'} mr={'0.5dvw'}>
                                <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Date of Birth / பிறந்த தேதி *</Typography>
                                <TextField type="date" size="small" sx={{ width: '98%' }}
                                    autoComplete="off"
                                    value={patientData.patient_dob}
                                    onChange={(e) => onChangeHandler(e, 'patient_dob')}
                                />
                            </Box>
                            <Box component={'div'} width={'30%'}>
                                <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Age / வயது</Typography>
                                <TextField size="small" sx={{ width: "100%", fontSize: "1dvw" }}
                                    autoComplete="off"
                                    value={patientData.patient_age}
                                    onChange={(e) => onChangeHandler(e, 'patient_age')}
                                />
                            </Box>
                        </Grid>

                        {/* <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Marital Status / திருமண நிலை</Typography>
                            <Autocomplete
                                clearIcon
                                size="small"
                                options={MARITAL_STATUS}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.label}
                                value={patientData?.patient_martial_sts}
                                onChange={(e, newValue) => {
                                    setPatientData((prevPatientData) => ({
                                        ...prevPatientData,
                                        patient_martial_sts: newValue?.value,
                                    }))
                                }}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} sx={{ width: '100%', fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }} />}
                                renderOption={(props, option) => (
                                    <li {...props} key={option?.label}>
                                        <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>{option?.label}</Typography>
                                    </li>
                                )}
                            />
                        </Grid> */}

                        {/* row 3 */}
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Father/ Husband Name / தந்தை/கணவன் பெயர்*</Typography>
                            <Box component={'div'} display={'flex'}>
                                <Autocomplete
                                    clearIcon
                                    size="small"
                                    options={RELATION_SHIP}
                                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option.label}
                                    value={patientData?.patient_relationship_fat_hus}
                                    onChange={(e, newValue) => {
                                        setPatientData((prevPatientData) => ({
                                            ...prevPatientData,
                                            patient_relationship_fat_hus: newValue
                                        }))
                                    }}
                                    sx={{ width: '30%' }}
                                    renderInput={(params) => <TextField {...params} sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }} />}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option?.label}>
                                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>{option?.label}</Typography>
                                        </li>
                                    )}
                                />
                                <TextField size="small" sx={{ width: "70%", fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}
                                    autoComplete="off"
                                    value={patientData?.patient_father_hus_name}
                                    onChange={(e) => onChangeHandler(e, 'patient_father_hus_name')}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Address 01 /முகவரி 01</Typography>
                            <TextField size="small" sx={{ width: "100%", fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}
                                autoComplete="off"
                                value={patientData?.patient_address_one}
                                onChange={(e) => onChangeHandler(e, 'patient_address_one')}
                            />
                        </Grid>

                        {/* row 4 */}

                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Address 02 /முகவரி 02</Typography>
                            <TextField size="small" sx={{ width: "100%", fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}
                                autoComplete="off"
                                value={patientData?.patient_address_two}
                                onChange={(e) => onChangeHandler(e, 'patient_address_two')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>City/நகரம்*</Typography>
                            <Autocomplete
                                clearIcon
                                size="small"
                                options={cityList}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option.name}
                                value={patientData?.patient_city}
                                onChange={(e, value) => {
                                    setPatientData((prevPatientData) => ({ ...prevPatientData, patient_city: value?.name }))
                                }}
                                renderInput={(params) => <TextField {...params} sx={{ width: '100%', fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }} />}
                                renderOption={(props, option) => (
                                    <li {...props} key={option?.name}>
                                        <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>{option?.name}</Typography>
                                    </li>
                                )}
                            />
                        </Grid>

                        {/* row 5 */}

                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>State/மாநிலம்*</Typography>
                            <Autocomplete
                                clearIcon
                                size="small"
                                options={stateList}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option.name}
                                value={patientData?.patient_state}
                                onChange={(e, value) => {
                                    let cityData = [];
                                    cityData = City.getCitiesOfState('IN', value?.isoCode)
                                    setPatientData((prevPatientData) => ({
                                        ...prevPatientData,
                                        patient_state: value,
                                        patient_city: ''
                                    }))
                                    setCityList(cityData)
                                }}
                                renderInput={(params) => <TextField {...params} sx={{ width: '100%', fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }} />}
                                renderOption={(props, option) => (
                                    <li {...props} key={option?.name}>
                                        <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>{option?.name}</Typography>
                                    </li>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Pin-code /பின்கோடு*</Typography>
                            <TextField size="small" sx={{ width: "100%", fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}
                                autoComplete="off"
                                value={patientData?.patient_pincode}
                                onChange={(e) => onChangeHandler(e, 'patient_pincode')}
                            />
                        </Grid>

                        {/* row 6 */}

                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Occupation / தொழில்</Typography>
                            <TextField size="small" sx={{ width: "100%", fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}
                                autoComplete="off"
                                value={patientData?.patient_occupation}
                                onChange={(e) => onChangeHandler(e, 'patient_occupation')}
                            />
                        </Grid>

                        {/* row 7 */}

                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Mobile No / கைபேசி எண் *</Typography>
                            <TextField size="small" sx={{ width: "100%", fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}
                                autoComplete="off"
                                value={patientData?.patient_mob_no}
                                onChange={(e) => onChangeHandler(e, 'patient_mob_no')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Email Id / மின்னஞ்சல் முகவரி</Typography>
                            <TextField size="small" sx={{ width: "100%", fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}
                                autoComplete="off"
                                inputProps={{
                                    maxLength: 50,
                                  }}
                                value={patientData?.patient_email_id}
                                onChange={(e) => onChangeHandler(e, 'patient_email_id')}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>Purpose Of Visit / வருகையின் நோக்கம் *</Typography>
                            <Autocomplete
                                clearIcon
                                size="small"
                                freeSolo
                                options={povOptions}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.reason}
                                value={patientData?.purposeOfVisit}
                                onChange={(e, newValue) => {
                                    setPatientData((prevPatientData) => ({
                                        ...prevPatientData,
                                        purposeOfVisit: newValue?.reason,
                                    }))
                                }}
                                sx={{ width: '100%' }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        sx={{ width: '100%', fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}
                                        onChange={(e) => {
                                            setPatientData((prevPatientData) => ({
                                                ...prevPatientData,
                                                purposeOfVisit: e.target.value,
                                            }))
                                        }}
                                    />}
                                renderOption={(props, option) => (
                                    <li {...props} key={option?.id}>
                                        <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>{option?.reason}</Typography>
                                    </li>
                                )}
                            />

                        </Grid>
                    </Grid>
                </Box>
                <Box component={'div'} sx={{ p: "1dvw 2dvw", borderBottom: '1px solid lightgray' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>{`How do you know about ${clinicData?.clinic_data?.clinic_name}?`}</Typography>
                            <Autocomplete
                                clearIcon
                                size="small"
                                options={referralSource}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option.source_name}
                                value={patientData?.patient_referral_source}
                                onChange={(e, value) => {
                                    setPatientData((prevPatientData) => ({
                                        ...prevPatientData,
                                        patient_referral_source: value?.source_name
                                    }))
                                }}
                                renderInput={(params) => <TextField {...params} sx={{ width: '100%', fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }} />}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box component={'div'} sx={{ p: "1dvw 2dvw", borderBottom: '1px solid lightgray' }}>
                    <FormControlLabel control={<Checkbox checked={patientData?.agreement_check} onChange={(e) => onChangeHandler(e, 'agreement_check')} />} label={<Typography component={'details'}>
                        <Typography component={'summary'} sx={{ fontSize: { xs: FONT_SIZE_XS, sm: FONT_SIZE_SM, md: FONT_SIZE_MD } }}>{"I agree to the Terms & Conditions for booking this appointment."}</Typography>
                        <Typography component={'p'} fontSize={'0.7rem'}>
                            {`I have been informed of the rules, regulations, and charges related to treatment at the hospital.
                        I give my consent to be examined (or the patient for whom I am responsible to be examined) by the hospital's doctors and nurses. This includes undergoing necessary tests, procedures, and treatments as prescribed by the healthcare providers.
                        I understand that the medical staff will take all reasonable precautions for my well-being and safety, but I acknowledge that inherent risks are associated with any medical procedure or treatment.`}
                        </Typography>
                    </Typography>} />
                </Box>
                <Box component={'div'} sx={{ p: "1dvw 2dvw", display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained" disabled={postLoad || checkValidation()}
                        sx={{ width: '15dvw', textTransform: 'capitalize' }}
                        onClick={() => {
                            setPostLoad(true)
                            onSubmitHandler()
                        }}
                    >Submit</Button>
                </Box>
                <div className="imagesall" data-testid="eMed_imagesall">
                    <div className="emedlogo">
                        <span className="alreadypat"><p data-testid="eMed_emedLogoText">Download EmedHub </p> To view your reports and bills</span>
                    </div>
                    <div className="appstoreandplaystore" data-testid="eMed_appstoreAndPlaystore">
                        <a href="https://apps.apple.com/us/app/emedhub/id6475773185?platform=iphone*" target="_blank" rel="nofollow" data-testid="eMed_appstoreLink"> <img src={ImagePaths.AppStoreNew} alt="Header" data-testid="eMed_appstoreImage" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.emedhub.clinic&pcampaignid=web_share" target="_blank" rel="nofollow" className="playstore" data-testid="eMed_playstoreLink"> <img src={ImagePaths.PlayStoreNew} alt="Header" data-testid="eMed_playstoreImage" /></a>
                    </div>
                </div>
                {
                    isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={isErrorMsgText}
                            msgPop={msgClose.bind(this)}
                        />
                        : null
                }
                {
                    successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={successMsgText}
                            msgPop={msgClose.bind(this)}
                        />
                        : null
                }
            </Box>


    );
};
